











import Vue from "vue";
import DataContainer from "@vuetifyx/common/DataContainer";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import Loading from "@/components/Loading.vue";
import BlurredTenant from "@/components/BlurredTenant.vue";

export default Vue.extend({
  components: { Loading, BlurredTenant },
  data() {
    return {
      showBlur: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
      self: this,
      tenantId: "",
      loading: false,
      dataContainer: new DataContainer(),
      formOptions: {
        attrs: {
          title: "Notify All",
        },
        content: {
          ignoreOtherFields: true,
          sections: {
            default: {
              fields: {
                title: {
                  type: "text",
                  attrs: {
                    label: "Title",
                  },
                },
                body: {
                  type: "text",
                  attrs: {
                    label: "Body",
                    required: true,
                  },
                },
              },
            },
          },
          actionButtons: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
            save: {
              content: {
                text: "Send",
              },
              on: {
                async xClick({ self }) {
                  const form = self.context();
                  const data = form.getData();
                  console.log('notify data = ', data)
                  form.loading.value = true;
                  self.loading.value = true;
                  await coreApiClient.call(
                    "notifications",
                    "create",
                    undefined,
                    data
                  );
                  self.loading.value = false;
                  form.loading.value = false;
                  form.success.value = "Notify successfully";
                },
              },
            },
          },
        },
      },
      formContainer: new DataContainer(),
    };
  },
  async created() {
    this.dataContainer.value = {
      title: '',
      body: ''
    }
  },
});
